.ContactPage{
    box-sizing: border-box;
    position: relative;
    height: auto;
}

.icon{
    margin-right: 10px;
    height: 30px;
    max-width: 100%;
}

.icon-text{
    display: flex;
    justify-content: center;
    align-items: center;
}

#mail{
    margin: 10px 0;
}

#phone{
    margin: 20px 0;
}

@media screen and (max-width: 992px){
    .icon{
        margin-right: 0;
        margin: 15px 0;
        height: 30px;
        max-width: 100%;
    }
    
    .icon-text{
        flex-direction: column;
    }

    #mail{
        margin: 10px 0;
        font-size: 15px;
    }
    
    #phone{
        margin: 20px 0;
    }
}