.SalePage{
    box-sizing: border-box;
    position: relative;
    height: auto;
}

.background-sale{
    z-index: -1;
    background-image: url('../Assets/sprzedaz.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    filter: blur(3px);
}

#loga{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

#loga a{
    display: flex;
    justify-content: center;
    align-items: center;
}

#loga img {
    width: 140px;
    height: auto;
}
