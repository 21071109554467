.HomePage{
    box-sizing: border-box;
    position: relative;
    height: auto;
}

#home-content {
    box-sizing: border-box;
    min-height: calc(100vh - 50px);
    padding: 100px 2.5%;
    font-size: 25px;
    display: flex;
    justify-content: space-evenly;
}

.image-container{
    width: 30%;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 0;
}

.sale{
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ), url("../Assets/sprzedaz.jpg") center center;
    background-size: cover;

}

.rental{
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ), url("../Assets/wynajem.jpg") center center;
    background-size: cover;
}

.project{
    background: linear-gradient(
        rgba(0, 0, 0, 0.6), 
        rgba(0, 0, 0, 0.6)
      ), url("../Assets/projekty.jpg") center center;
    background-size: cover;
}

.sale::after,
.rental::after,
.project::after{
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: inherit;
    background-size: cover;
    transform-origin: center;
    transition: transform .4s ease-in-out;
}

.sale:hover::after,
.rental:hover::after,
.project:hover::after{
    transform: scale(1.1);
}

.tile-title{
    position: relative;
    z-index: 1;
    font-weight: 700;
    font-size: 2.5vw;
    text-align: center;
    color: white;
}

@media screen and (max-width: 750px){
    #home-content{
        flex-direction: column;
        align-items: space-evenly;

    }
    .image-container{
        width: 100%;
        height: 30%;
        justify-content: center;
        flex: 0.3;
    }
    .tile-title{
        font-size: 2.5vh;
    }
}