.Error404Page{
    padding: 80px 2.5%;
    box-sizing: border-box;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Error404Page > div {
    display: flex;
    align-items: center;
}

.Error404Page h1{
    font-size: 100px;
    font-weight: 500;
    margin: 0;
}

.Error404Page h2{
    font-weight: 400;
    margin: 0;
}

#sad{
    font-size: 160px;
    margin-right: 50px;
}