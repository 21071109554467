.ProjectPage{
    box-sizing: border-box;
    position: relative;
    height: auto;
}

.background-project{
    z-index: -1;
    background-image: url('../Assets/projekty.jpg');
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.2;
    filter: blur(3px);
}