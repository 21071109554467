.Navbar {
    width: 100%;
    height: 65px;
    position: absolute;
    display: flex;
    padding: 0 2.5% 0 2.5%;
    box-sizing: border-box;
    margin-top: 10px;
    top:0;
    z-index: 1;
}

.links{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid #08357C;
}

.pointer{
    cursor: pointer;
}

ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;
    gap: 20px;
    font-size: 17px;
}

ul li{
    position: relative;
    display: flex;
    align-items: center;
}

ul li:hover::after,
ul li.active::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #08357C;
    bottom: 0px;
    left: 0;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.burger{
    display: none;
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 3px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

.change .bar1 {
    transform: translate(0, 9px) rotate(-45deg);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
    transform: translate(0, -9px) rotate(45deg);
}

#footer{
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.05);
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer > p{
    font-size: 1.1vw;
    opacity: 40%;
}

@media screen and (max-width: 992px){
    ul {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        width: 300px;
        height: 100%;
        background-color: white;
        top: 0;
        right: -310px;
        padding: 80px 0 0 10px;
        transition: 0.3s ease-in-out;
        z-index: 2;
    }

    ul li {
        margin: 10px 0;
    }

    .burger{
        z-index: 2;
        display: inline-block;
        cursor: pointer;
    }

    ul.active{
        right: 0;
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        backdrop-filter: blur(3px);
    }

    ul li:hover::after,
    ul li.active::after{
        border-radius: 2px;
    }

    #footer > p{
        font-size: 1.1vh;
    }

}
