body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: aliceblue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  text-decoration: none;
  color: #08357C;
}

p{
  margin: 0px;
}

.content{
  box-sizing: border-box;
  min-height: calc(100vh - 50px);
  padding: 100px 11% 30px 11%;
  font-size: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


section{
  width: 100%;
  display: flex;
}

.text{
  margin: auto;
}

section:not(:first-child){
  margin-top: 50px;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.w-50 {
  width: 50%;
}

.w-100{
  width: 100%;
}

.text{
  text-align: center;
  margin: 30px 0;
  font-weight: 500;
  font-size: 22px;
}

.lp{
  padding-left: 10px;
}

.header{
  font-size: 30px;
  font-weight: 500;
  text-align: center;
}

.price{
  font-size: 50px;
  margin: 30px 0;
  font-weight: 500;
  text-align: center;
}

.w-40>img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.w-100 h1{
  text-align: center;
  margin: 30px 0;
  font-weight: 500;
  font-size: 40px;
}

button {
  width: 100%;
  height: 70px;
  background-color: transparent;
  cursor: pointer;
  border: solid black 2px;
  border-radius: 10px;
  font-size: large;
  font: inherit;
}

@media screen and (max-width: 992px){
  section{
      flex-direction: column;
  }

  .w-40,
  .w-50,
  .w-60{
      width: 100%;
  }

  .w-100>h1{
      font-size: 30px;
  }

  .lp{
      padding-left: 0px;
  }
}


.w-60 h1{
  text-align: center;
  margin: 30px 0;
  font-weight: 500;
  font-size: 50px;
}

.bold{
  font-weight: 700;
  font-size: inherit;
}